<template>
    <div class="container">
        <div class="container_body1">
            <Header :sumbut="sumbut" :Selectfactory="Selectfactory" />
        </div>
        <div class="container_body2">
            <Chartone ref="OQCChartone"></Chartone>
        </div>
        <div class="container_body_3">
            <div style="height: 32px;">
                <span class="span_date">{{this.$t('All.日期区间')}}</span>
                    <el-date-picker
                    v-model="value1"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="~"
                    :start-placeholder="this.$t('All.开始日期')"
                    :end-placeholder="this.$t('All.结束日期')"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions" size="small" @change="Selectdate">
                    </el-date-picker>
            </div>
            <div class="container_body3">
                <div class="body3_chart1">
                    <Chartthree ref="OQCChartthree"></Chartthree>
                </div>
                <div class="body3_chart2">
                    <Charttwo :Getrunt="Getrunt" ref="OQCCharttwo"></Charttwo>
                </div>
            </div>
        </div>
        <div class="container_body4">
            <Chartfour ref="OQCChartfour"></Chartfour>
        </div>
    </div>
</template>

<script>
export default {
  components: {
    Header: () => import('./Header.vue'),
    Chartone: () => import('./Chartone.vue'),
    Charttwo: () => import('./Charttwo.vue'),
    Chartthree: () => import('./Chartthree.vue'),
    Chartfour: () => import('./Chartfour.vue')
  },
  data () {
    return {
      value1: '',
      pickerOptions: {
        shortcuts: [{
          text: this.$t('All.最近一周'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近一个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近三个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      cq: '',
      value2: ''
    }
  },
  created () {
    this.cq = this.$store.state.Login.Factory
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth()

    // 当前月份的第一天
    const monthStart = this.formatDate(new Date(year, month, 1))

    // 当前月份的最后一天
    const monthEnd = this.formatDate(new Date(year, month + 1, 0))
    this.value1 = [monthStart, monthEnd]
    this.value2 = [this.formatDate(new Date()), this.formatDate(new Date())]
  },
  methods: {
    sumbut (row1) {
      this.value2 = row1
      this.$refs.OQCChartone.Getdata(this.cq === 'All' ? 'TC' : this.cq, this.value2[0], this.value2[1])
    },
    Selectfactory (row1) {
      this.cq = row1
      this.$refs.OQCChartone.Getdata(row1, this.value2[0], this.value2[1])
      this.$refs.OQCCharttwo.Getdata(row1, this.value1[0], this.value1[1])
      this.$refs.OQCChartthree.Getdata(row1, this.value1[0], this.value1[1])
      this.$refs.OQCChartfour.Getdata(row1, this.value1[0], this.value1[1])
    },
    // 格式化函数，确保月份和日期为两位数
    formatDate (date) {
      const y = date.getFullYear()
      const m = (date.getMonth() + 1).toString().padStart(2, '0') // 月份加 1，确保两位数
      const d = date.getDate().toString().padStart(2, '0') // 确保日期是两位数
      return `${y}-${m}-${d}`
    },
    Selectdate () {
      this.$refs.OQCCharttwo.Getdata(this.cq === 'All' ? 'TC' : this.cq, this.value1[0], this.value1[1])
      this.$refs.OQCChartthree.Getdata(this.cq === 'All' ? 'TC' : this.cq, this.value1[0], this.value1[1])
      this.$refs.OQCChartfour.Getdata(this.cq === 'All' ? 'TC' : this.cq, this.value1[0], this.value1[1])
    },
    Getrunt (row) {
      this.$refs.OQCChartthree.Getdata(this.cq === 'All' ? 'TC' : this.cq, this.value1[0], this.value1[1], row)
    }
  }

}

</script>

<style lang="less" scoped>
.container {
    padding: 0;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}
.container_body1 {
    height: 5%;
}
.container_body2 {
    height: 31%;
}
.container_body_3{
    height: 31%;
}
.container_body3 {
    height: calc(100% - 32px);
    display: flex;
    flex-direction: row;
}
.container_body4 {
    height: 33%;
}
.body3_chart1 {
    width: 40%;
    height: 100%;
}
.body3_chart2 {
    width: 60%;
    height: 100%;
}
.span_date {
    margin-right: 5px;
}
</style>
